<template>
  <transition
    :enter-active-class="$style.backgroundEnterTransition"
    enter-from-class="fade-out"
    leave-active-class="fade-leave-transition"
    leave-to-class="fade-out"
  >
    <div
      v-if="showDialog"
      :class="backgroundClassNames"
    >
      <transition
        appear
        :enter-active-class="$style.dialogEnterTransition"
        enter-from-class="fade-out"
        leave-active-class="fade-leave-transition"
        leave-to-class="fade-out"
      >
        <slot
          @close="handleClose"
        />
      </transition>
    </div>
  </transition>
</template>

<script>
/* eslint vue/no-reserved-component-names: off */

import { ButtonTypes } from '@@/components/Common/Button.vue';

export default {
  name: 'Dialog',

  props: {
    closeOnEscape: {
      type: Boolean,
      default: true,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close'],

  computed: {
    buttonTypes() {
      return ButtonTypes;
    },

    backgroundClassNames() {
      return [
        'tw-fixed tw-inset-0 tw-z-50',
        'tw-grid tw-items-center tw-justify-center',
        this.$style.background,
      ];
    },
  },

  watch: {
    showDialog() {
      if (!this.closeOnEscape) {
        return;
      }

      if (this.showDialog === true) {
        window.addEventListener('keydown', this.handleKeydown);
      }
      else {
        window.removeEventListener('keydown', this.handleKeydown);
      }
    },
  },

  methods: {
    handleClose() {
      this.$emit('close');
    },

    handleKeydown(e) {
      if (e.keyCode === 27) {
        this.handleClose();
      }
    },
  },
};
</script>

<style module>
.backgroundEnterTransition {
  transition: opacity var(--fade-transition-time) ease-in-out;
}

.dialogEnterTransition {
  transition: opacity var(--fade-transition-time) ease-in-out var(--fade-transition-time);
}

.background {
  backdrop-filter: blur(1px);
  background-color: rgb(0 0 0 / 50%);
  grid-template-columns: 90vw;
}

@media (min-width: 768px) {
  .background {
    grid-template-columns: 60vw;
  }
}

@media (min-width: 992px) {
  .background {
    grid-template-columns: min-content;
  }
}
</style>
