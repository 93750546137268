<template>
  <Dialog
    :show-dialog="showDialog"
    @close="handleClose"
  >
    <SelectFavoriteListCard
      v-if="showDialog"
      :action="action"
      :cam="cam"
      :daily-read="dailyRead"
      :default-selected-lists="defaultSelectedLists"
      :location="location"
      :season-pass="seasonPass"
      :show-close-button="true"
      :trail-map="trailMap"
      @close="handleClose"
      @continue="handleContinue"
    />
  </Dialog>
</template>

<script>
/**
 * Display a dialog allowing the user to select a single or multiple favorite lists. A user may
 * choose a single favorite list to view on the Favorites pages. And when saving or removing a
 * favorite item, the user may chose multiple favorite lists to save the item to or remove from.
 * When configured to select a single favorite list radio buttons will be displayed. And when
 * configured to select multiple favorite lists then check boxes will be displayed.
 */
export default {
  name: 'SelectFavoriteListDialog',

  props: {
    action: {
      type: String,
      default: 'add',
    },
    cam: {
      type: Object,
      default: null,
    },
    dailyRead: {
      type: Object,
      default: null,
    },
    defaultSelectedLists: {
      type: Array,
      default: null,
    },
    location: {
      type: Object,
      default: null,
    },
    seasonPass: {
      type: Object,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    trailMap: {
      type: Object,
      default: null,
    },
  },

  emits: ['close', 'continue'],

  methods: {
    handleClose() {
      this.$emit('close');
    },

    handleContinue(selectedLists) {
      this.$emit('continue', selectedLists);
    },
  },
};
</script>
