<template>
  <input
    :checked="modelValue"
    :class="inputClass"
    type="checkbox"
    @input="(e) => $emit('update:modelValue', e.target.checked)"
  >
</template>

<script>
/**
 * Custom styled checkbox input with v-model interface.
 * @see https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/
 */
export default {
  name: 'Checkbox',

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  computed: {
    inputClass() {
      return [
        'tw-appearance-none',
        'tw-relative',
        'tw-inline-block',
        'tw-h-5',
        'tw-w-5',
        'tw-border-2',
        'tw-rounded-md',
        'card-background-color',
        'border-color',
        'tw-transition-all',
        'input-checkable',
      ];
    },
  },
};
</script>
