import { mapActions, mapState } from 'pinia';
import { areArraysDifferent } from '@@/utils/CommonUtils';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';

export default {
  computed: {
    ...mapState(useUserFavoritesStore, [
      'getFavoriteListsForCam',
      'getFavoriteListsForDailyRead',
      'getFavoriteListsForLocation',
      'getFavoriteListsForSeasonPass',
      'getFavoriteListsForTrailMap',
    ]),

    ...mapState(useUserFavoritesStore, {
      favoriteIds: (state) => state.user?.favoriteIds,
      lists: (state) => state.user?.lists,
    }),
  },

  methods: {
    ...mapActions(useUserFavoritesStore, [
      'addFavoriteCam',
      'addFavoriteDailyRead',
      'addFavoriteLocation',
      'addFavoriteSeasonPass',
      'addFavoriteTrailMap',
      'removeFavoriteCam',
      'removeFavoriteDailyRead',
      'removeFavoriteLocation',
      'removeFavoriteSeasonPass',
      'removeFavoriteTrailMap',
    ]),

    async addFavorite(resource, listIds) {
      let newFavoriteResource;

      try {
        newFavoriteResource = await this.addFavoriteResource(resource, listIds);

        this.$toast.open({
          message: `${this.getResourceName(resource)} was added to your ${this.getListName(listIds)} favorites.`,
          type: 'success',
        });
      }
      catch (e) {
        this.handleError('add', e, resource);
        return false;
      }

      return this.isLocation(resource) ? newFavoriteResource : true;
    },

    addFavoriteResource(resource, list_ids, reset_list_membership) {
      let id;
      let add;

      if (this.isCam(resource)) {
        ({ id } = resource.cam);
        add = this.addFavoriteCam;
      }

      if (this.isDailyRead(resource)) {
        ({ id } = resource.dailyRead);
        add = this.addFavoriteDailyRead;
      }

      if (this.isLocation(resource)) {
        ({ id } = resource.location);
        add = this.addFavoriteLocation;
      }

      if (this.isSeasonPass(resource)) {
        ({ id } = resource.seasonPass);
        add = this.addFavoriteSeasonPass;
      }

      if (this.isTrailMap(resource)) {
        ({ id } = resource.trailMap);
        add = this.addFavoriteTrailMap;
      }

      return add({ id, list_ids, reset_list_membership });
    },

    getFavoriteListstForResource(resource) {
      if (this.isCam(resource)) {
        return this.getFavoriteListsForCam(resource.cam);
      }

      if (this.isDailyRead(resource)) {
        return this.getFavoriteListsForDailyRead(resource.dailyRead);
      }

      if (this.isLocation(resource)) {
        return this.getFavoriteListsForLocation(resource.location);
      }

      if (this.isSeasonPass(resource)) {
        return this.getFavoriteListsForSeasonPass(resource.seasonPass);
      }

      if (this.isTrailMap(resource)) {
        return this.getFavoriteListsForTrailMap(resource.trailMap);
      }

      return null;
    },

    getListName(listIds) {
      const listName = listIds?.length === 1
        ? this.lists?.find(({ id }) => id === listIds[0]).name
        : null;

      return listName || '';
    },

    getResourceName(resource) {
      if (this.isCam(resource)) {
        return `${resource.cam.name} (${resource.location.name})`;
      }

      if (this.isDailyRead(resource)) {
        return `The ${resource.dailyRead.name} Daily Snow`;
      }

      if (this.isLocation(resource)) {
        return resource.location.name;
      }

      if (this.isSeasonPass(resource)) {
        return resource.seasonPass.name;
      }

      if (this.isTrailMap(resource)) {
        return `${resource.trailMap.name} (${resource.location.name})`;
      }

      return '';
    },

    handleError(action, e, resource) {
      let defaultMessage = 'Unable to update your favorites';

      if (action === 'remove') {
        defaultMessage = `Unable to remove ${this.getResourceName(resource)} from your favorites`;
      }
      else if (action === 'add') {
        defaultMessage = `Unable to add ${this.getResourceName(resource)} to your favorites`;
      }

      const message = e.response?.data?.messages?.[0] || defaultMessage;
      this.$toast.open({ message, type: 'error' });
    },

    isCam(resource) {
      return typeof resource.cam !== 'undefined';
    },

    isDailyRead(resource) {
      return typeof resource.dailyRead !== 'undefined';
    },

    isLocation(resource) {
      // Cams and trail maps will be passed in the resource object with their corresponding
      // location, so that the location name can be used in toast messages. So to determine whether
      // a resource is truly a location, there must be no cam and trial map!
      return typeof resource.location !== 'undefined'
        && !this.isCam(resource)
        && !this.isTrailMap(resource);
    },

    isSeasonPass(resource) {
      return typeof resource.seasonPass !== 'undefined';
    },

    isTrailMap(resource) {
      return typeof resource.trailMap !== 'undefined';
    },

    async updateFavorite(resource, listIds = [], showToast = true) {
      let isRemoved = false;
      const prevListIds = this.getFavoriteListstForResource(resource);

      if (areArraysDifferent(listIds, prevListIds) === false) {
        return isRemoved;
      }

      try {
        if (listIds.length === 0) {
          await this.removeFavoriteResource(resource, prevListIds);
          isRemoved = true;
        }
        else {
          await this.addFavoriteResource(resource, listIds, true);
        }

        if (showToast) {
          const message = isRemoved ? `${this.getResourceName(resource)} was removed from your favorites` : 'Your favorites were updated.';
          const type = isRemoved ? 'warning' : 'success';
          this.$toast.open({ message, type });
        }
      }
      catch (e) {
        const action = isRemoved ? 'remove' : 'update';
        this.handleError(action, e, resource);
      }

      return isRemoved;
    },

    async removeFavorite(resource, listIds = null, showToast = true) {
      let isRemoved = true;

      try {
        await this.removeFavoriteResource(resource, listIds);

        if (showToast) {
          this.$toast.open({
            message: `${this.getResourceName(resource)} was removed from your ${this.getListName(listIds)} favorites.`,
            type: 'warning',
          });
        }
      }
      catch (e) {
        this.handleError('remove', e, resource);
        isRemoved = false;
      }

      return isRemoved;
    },

    async removeFavoriteResource(resource, prevListIds) {
      let id;
      let remove;

      if (this.isCam(resource)) {
        ({ id } = resource.cam);
        remove = this.removeFavoriteCam;
      }

      if (this.isDailyRead(resource)) {
        ({ id } = resource.dailyRead);
        remove = this.removeFavoriteDailyRead;
      }

      if (this.isLocation(resource)) {
        ({ id } = resource.location);
        remove = this.removeFavoriteLocation;
      }

      if (this.isSeasonPass(resource)) {
        ({ id } = resource.seasonPass);
        remove = this.removeFavoriteSeasonPass;
      }

      if (this.isTrailMap(resource)) {
        ({ id } = resource.trailMap);
        remove = this.removeFavoriteTrailMap;
      }

      await remove({ id, list_ids: prevListIds });
    },
  },
};
